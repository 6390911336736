@import "src/theme/variables/colors";

.ant-input:focus {
  border-color: $secondary;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  margin-top: -32px;
  margin-left: 80px;
  z-index: 9;

  @media screen and (min-width: $large-screen) {
    margin-top: 80px;
    margin-left: -40px;
  }
}

.custom-file-upload-button {
  background-color: $primary;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  & > svg {
    height: 100%;
  }
}