@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

.adm-floating-panel {
  touch-action: none;
  background-color: #16151d;
  max-width: $small-screen;
  @media screen and (min-width: $small-screen) {
    //
  }
}