$app-padding: 24px;
$full-mobile-width: calc(100% + 2 * 24px);
$full-mobile-width-absolute: calc(100% - 2 * 24px);
$padding-with-tab-bar: calc(24px + 70px);
$height-with-tabbar: calc(100vh - 70px);

$navbar-width: 300px;
$topbar-desktop-height: 80px;
$topbar-desktop-height-scrolled: 60px;
$topbar-mobile-height: 80px;
$topbar-mobile-height-scrolled: 60px;
$desktop-main-padding: 64px;

$small-screen: 476px;
$medium-screen: 768px;
$large-screen: 960px;
