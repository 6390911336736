@import "src/theme/variables/colors";

.job-card-wrapper {
  margin-bottom: 16px;
  background-color: $gray-4;

  & > .adm-card-body {
    display: flex;
    column-gap: 12px;
  }
}

.job-card-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  height: 60px;
  & > .job-card-footer {
    color: $gray-2;
    & > a {
      text-decoration: underline;
    }
  }
}

.job-card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  & > .adm-tag:not(:last-of-type) {
    //margin-bottom: 8px;
  }
  & > .adm-tag-round {
    border-radius: 5px;
  }

  & > .job-card-footer {
    color: $gray-2;
    font-size: 12px;
    line-height: 12px;
  }
}

.job-card-title {
  font-size: 14px;
  line-height: 14px;
}

.job-card-subtitle {
  text-transform: capitalize;
}

.adm-card-body {
  & > .ant-avatar-image {
    align-self: center;
    border-radius: 5px;
  }
}

.job-application__score {
  color: #B49FF1;
}

.job-application__date {
  color: $gray-1;
  font-size: 11px;
}