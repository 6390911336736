@import "src/theme/variables/spaces";

.adm-tab-bar-item {
  height: 70px;
  @media screen and (max-width: $large-screen) {
    width: 20vw;
  }

  //@media screen and (min-width: $large-screen) {
  //  flex-direction: row;
  //  column-gap: 8px;
  //}
}

.adm-tab-bar-item-title {
  font-size: 9px;
  margin-top: 4px;
}