@import "../../theme/variables/colors";
@import "../../theme/variables/spaces";

.navbar-wrapper {
  position: fixed;
  left: 0;
}

.mobile-navbar-wrapper {
  bottom: 0;
  width: 100%;
  background-color: $app-background-color;
  border-top: 2px solid $gray-border;
  height: 70px;
  min-height: 70px;

  @media screen and (max-width: $large-screen) {
    left: 50%;
    transform: translateX(-50%);
    max-width: $small-screen;
  }
}

.desktop-navbar__wrapper {
  background: linear-gradient(180deg, rgba(38, 34, 80, 0.69) 0%, rgba(38, 34, 80, 0.85) 57.44%, #17163B 100%);
  padding: 0 48px 48px 48px;
  width: $navbar-width;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  & > .adm-tab-bar-wrap {
    display: flex;
    flex-direction: column;
  }
}

.desktop-navbar__logo-wrapper {
  height: $topbar-desktop-height;
}

.desktop-navbar__body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 48px;
  height: calc(100% - #{$topbar-desktop-height});
}

.desktop-navbar__item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  &:hover {
    color: $secondary;
  }

  & > span {
    margin-left: 16px;
  }

  & > sup {
    height: 8px;
    width: 8px;
    background-color: $success;
    border-radius: 50%;
    transform: translate(-80%, -60%);
    margin-right: -8px;
  }

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.desktop-navbar__item-selected {
  color: $secondary !important;
}

.navbar-icon__active {
  @media screen and (min-width: $large-screen) {
    stroke: $secondary;
  }
}

.adm-tab-bar-item-active {
  justify-content: flex-start;
  padding-top: 4px;
}
