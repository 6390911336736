@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

// Make tabs wrapper wider to fit screen size
.full-tabs-wrapper {
  & > .adm-tabs-content {
    position: absolute;
    padding: $app-padding;
    min-height: 240px;
    width: 100%;
    height: calc(100vh - 70px - 45px - 77px);
    overflow-y: auto;
    overscroll-behavior: contain;

    @media screen and (min-width: $small-screen) {
      padding: $app-padding 0;
    }
  }
}

// Make tabs wider and with smaller font
.adm-tabs-tab {
  margin: 0 !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 15px;
}

// Change color of active tab
.adm-tabs-tab-active {
  color: #fff !important;
}

// Change bottom active line color
.adm-tabs-tab-line {
  background: #9C81EA !important;
}

// Remove tabs wrapper border bottom
.adm-tabs-header {
  border-bottom: none !important;
}

// Remove padding for each tab
.adm-tabs-tab-list {
  & > .adm-tabs-tab-wrapper {
    padding: 0;
  }
}

.adm-tabs-tab-list > .adm-tabs-tab-wrapper {
  flex: 1;
}
