@import "src/theme/variables/colors";

.custom-form-select {
  height: 58px;
  padding: 8px !important;
  background-color: $input-background;
  & > .ant-select-selector {
    background: transparent !important;
    border-color: $secondary !important;
    font-size: 14px !important;
  }

  & > .ant-select-arrow {
    color: $gray-1;
  }
}

.tag-list-wrapper:not(:last-of-type) {
  margin-bottom: 32px;
}

.custom-form-textarea-item-explain-error {
  color: $error;
  margin-top: 4px;
}