@import "src/theme/variables/colors";

.ant-btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;

}

.ant-btn-dangerous, .ant-btn-dangerous:hover {
  background-color: $error !important;
  border-color: $error !important;
}