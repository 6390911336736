@import "src/theme/variables/colors.scss";
@import "src/theme/variables/spaces.scss";

body {
  font-family: "Roboto Mono for Powerline", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #fff;
  //max-width: $small-screen;
  background-color: $app-background-color;
  margin: auto;

  @media screen and (min-width: $large-screen) {
    margin: 0;
    max-width: 100%;
  }
}

#root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

a, a:hover {
  color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff !important;
}

ul {
  padding-inline-start: 16px;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root:root {
  --adm-color-primary: #5362E9;
  --adm-color-success: #9C81EA;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: #fff;
  --active-line-height: 3px;

  --adm-font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue",
    helvetica, segoe ui, arial, roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
  -webkit-transition-delay: 99999s;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}