@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

.job-filters__body {
  height: calc(100% - 85px - 28px - 48px - 24px);
  overflow-y: scroll;
  padding: 0 24px;
  overscroll-behavior: contain;

  @media screen and (min-width: $large-screen) {
    padding: 0;
    height: 100%;
  }
}

.filters-apply-button {
  width: $full-mobile-width-absolute;
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.search-filters__mobile-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  & > .search-filters__mobile-navbar-left {
    width: 80px;
    text-align: left;
    & > svg {
      cursor: pointer;
    }
  }
  & > .search-filters__mobile-navbar-title {
    font-size: 16px;
  }
  & > .search-filters__mobile-navbar-right {
    width: 80px;
    text-align: right;
  }
}

.search-filters__desktop-wrapper {
  width: 700px !important;

  & > .ant-modal-content {
    background-color: $app-background-color;
    & > .ant-modal-header {
      background-color: $app-background-color !important;
      color: #fff !important;
      border: none;
      & > .ant-modal-title {
        color: #fff;
      }
    }
    & > .ant-modal-close {
      color: #fff;
    }
  }
}

.search-filters__desktop-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}