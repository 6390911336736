@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.dorse-landing__wrapper {
  background-color: #070E27;
}

.dorse-landing__navbar {
  height: 70px;
  border-bottom: 1px solid #232740;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  z-index: 999;

  @media screen and (min-width: $small-screen) {
    padding: 0 96px;
  }
}

.dorse-landing__login-button, .dorse-landing__login-button:hover {
  border-color: #7E8BB6 !important;
  color: #7E8BB6 !important;
}

.dorse-landing__get-started-gray-button {
  background-color: #7A7AD5 !important;
  border-radius: 8px !important;
  color: #fff !important;
  border: none !important;
}

.dorse-landing__content {
  padding: 24px;

  @media screen and (min-width: $small-screen) {
    padding: 96px;
  }

  @media screen and (min-width: $large-screen) {
    padding: 96px;
  }
}

.dorse-landing__button {
  background-color: #222C6D;
  color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
}

.dorse-landing__responsive-row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;

  &.reversed {
    flex-direction: row-reverse;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.dorse-landing__text {
  max-width: $small-screen;
  padding: 48px;
  padding-left: 96px;
  color: #fff;
  text-align: left;
  align-self: center;

  & > .description {
    color: #7E8BB6;
    margin-bottom: 16px;
  }

  @media screen and (max-width: $small-screen) {
    padding: 24px;
  }

  @media screen and (max-width: 800px) {
    padding: 72px;
  }
}

.dorse-landing__image {
  max-height: 400px;
  padding: 48px 0;
  margin-left: auto;
  margin-right: 96px;

  & > img {
    max-height: 400px;
  }

  @media screen and (max-width: $small-screen) {
    padding: 24px;
    margin-right: auto;
  }

  @media screen and (max-width: $large-screen) {
    max-width: 400px;
    margin-right: auto;
  }
}

.dorse-landing__features {
  display: grid;
  gap: 24px;
  grid-template-columns: auto auto;

  @media screen and (max-width: $small-screen) {
    grid-template-columns: auto;
  }
}

.dorse-landing__box {
  border: 1px solid #191E36;
  border-radius: 8px;
  padding: 24px;
  text-align: left;
  align-self: center;
  color: #fff;
  height: 100%;

  & > .description {
    color: #7E8BB6;
  }
}

.dorse-landing__ready {
  text-align: center;
  color: #fff;
  padding: 72px 24px;
  background-color: #5454D4;
  margin: 48px 0;
  border-radius: 8px;

  & > .header {
    color: #9F9FE7;
  }
}

.dorse-landing__footer {
  display: flex;
  padding: 0 96px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #161844;
  height: 60px;
  color: #CCD2E9;
  font-size: 12px;

  @media screen and (max-width: $small-screen) {
    padding: 0 24px;
  }

  & > :last-child {
    @media screen and (max-width: $small-screen) {
      display: none;
    }
  }
}

.dorse-landing__burger-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 6px;
  cursor: pointer;
  @media screen and (min-width: $small-screen) {
    display: none;
  }
}

.dorse-landing__burger-menu-line {
  height: 1.5px;
  width: 22px;
  background-color: #fff;
}

.dorse-landing__burger-menu-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: -150px;
  background-color: #070E27;
  transition: height ease 0.2s;
  z-index: 1;

  & > div {
    padding: 24px;
    color: #7E8BB6;
    border-bottom: 1px solid #232740;

    & > span {
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
}

.dorse-landing__burger-menu-content-open {
  top: 70px;
}

.dorse-landing__desktop-menu {
  display: none !important;
  @media screen and (min-width: $small-screen) {
    display: flex !important;
  }
}