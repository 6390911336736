@import "src/theme/variables/colors";

.navbar-right-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
}

.dashboard__navbar-wrapper {
  margin-bottom: 24px;
}

.dashboard__filters-wrapper {
  margin-bottom: 16px;
}