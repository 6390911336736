@import "src/theme/variables/colors";

// Restyle default tags
.custom-ant-tag {
  border-radius: 2px !important;
  padding: 4px 8px !important;
  background-color: transparent;
  color: $gray-text;
  border: 1px solid $gray-border;
  text-transform: capitalize;

  &.selectable-tag {
    &:hover {
      background-color: $primary !important;
      border-color: $primary;
      color: #fff !important;
      cursor: pointer !important;
    }
  }
}

.profile-tag {
  text-transform: uppercase !important;
  padding: 2px 4px 0 !important;
}

// Change colors for selected tag
.checked-tag {
  background-color: $primary !important;
  color: #fff !important;
  border: none;
}
