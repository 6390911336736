@import "src/theme/variables/spaces";

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
