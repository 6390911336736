@import "src/theme/variables/colors";

.job-box__container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-moz-viewport-scroll {
    display: none;
  }
}

.job-box__wrapper {
  background-color: $gray-4;
  padding: 8px;
  color: #fff;
  border-radius: 5px;
  height: 300px;
  width: 180px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & > header {
    flex: 1;
  }
}

.job-box__header {
  text-align: end;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 16px;
}

.job-box__title {
  min-height: 44px;
}

.job-box__location {
  color: $gray-1 !important;
  margin-bottom: 8px;
}

