@import "../../theme/variables/spaces";
@import "../../theme/variables/colors";

.auth__brand-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}

.auth__brand-title {
  font-size: 40px;
}

.auth__button-social {
  color: $gray-2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-top: 16px;
}

.auth__button-mobile {
  margin-top: 24px;
}

.auth__white-text {
  color: #fff;
  margin-top: 16px;
  font-family: "Roboto Mono for Powerline", sans-serif;
  & > a {
    margin-left: 8px;
  }
}