@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.desktop-topbar__wrapper {
  height: $topbar-desktop-height;
  width: calc(100% - 300px);
  border-bottom: 1px solid $gray-1;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: $navbar-width;
  background-color: $app-background-color;
  z-index: 999;
  transition: height 0.1s ease;
}

.desktop-topbar__wrapper-override {
  z-index: 9999;
}

.desktop-topbar__scrolled {
  height: $topbar-desktop-height-scrolled;
}

.desktop-topbar__not-logged-in {
  left: 0;
  width: 100%;
}

.topbar__title {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    cursor: pointer;
    margin-right: 8px;
  }

  & > .topbar__title-text {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.desktop-topbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notifications-tag {
  background-color: $success !important;
  color: #000 !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 4px 12px !important;
  cursor: pointer;
  margin-right: 16px !important;

  &.notifications-tag__unread {
    background-color: $error !important;
    color: #fff !important;
  }
}

.user-avatar {
  margin-right: 8px !important;
}

.mobile-topbar__wrapper {
  height: $topbar-mobile-height;
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $app-background-color;
  z-index: 99;
  transition: height 0.1s ease;

  @media screen and (min-width: $small-screen) {
    padding: $app-padding calc((100% - #{$small-screen}) / 2);
  }
}

.mobile-topbar__wrapper-scrolled {
  height: $topbar-mobile-height-scrolled !important;
}

.mobile-topbar__left {
  min-width: 24px;
}

.mobile-topbar__right {
  min-width: 24px;
}

.mobile__message-icon {
  cursor: pointer;
}

.mobile__notifications-tag {
  background-color: $success !important;
  color: #000 !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 4px 12px !important;
  cursor: pointer;
  margin-right: 16px !important;

  &.mobile__notifications-tag__unread {
    background-color: $error !important;
    color: #fff !important;
  }
}

.user-avatar {
  margin-right: 8px !important;
}