@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.adm-form {
  & > .adm-list {
    & > .adm-list-body {
      background-color: transparent;
      border-top: none;
      border-bottom: none;
    }
  }
}

.adm-list-item {
  padding-left: 0;
}

.adm-list-item-content {
  border-color: $secondary;
}

.adm-list-item-content-extra {
  align-self: flex-end;
  color: $secondary;
}

.adm-form-item-label-required {
  display: none;
}

.ant-form-item-required {
  color: #fff !important;
  &:before {
    content: none !important;
  }
}

.ant-form {
  //overflow-y: auto;

  @media screen and (min-width: $large-screen) {
    //padding: $desktop-main-padding;
  }
}

.ant-form label {
  font-size: 12px;
}

.ant-form-item-label > label {
  color: $gray-2 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  margin-bottom: 8px !important;
}

.adm-form .adm-form-footer {
  padding: 0;
}

.ant-form-item-label {
  padding-bottom: 0 !important;
  text-align: left !important;

  & > label::after {
    content: none;
  }
}

.ant-form-item {
  flex-flow: column;
}