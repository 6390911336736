@import "src/theme/variables/colors";

.ant-list-item {
  color: $gray-2;
}

.ant-list-item-meta-title > a {
  color: #fff;
}

.ant-list-item-meta-description {
  color: $gray-1;
}

.ant-list-footer {
  color: #fff;
}
