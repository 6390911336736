span.ant-radio + * {
  padding: 0;
}

.ant-radio {
  display: none;
}

.ant-radio-wrapper {
  margin-right: 0;
  margin-bottom: 8px;
}

//.ant-radio-wrapper-checked {
//  & > span {
//    & >
//  }
//}