@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

// Override whole navbar
.adm-nav-bar {
  padding: 0 !important;
  height: $topbar-mobile-height;
}

// Override title
.adm-nav-bar-left {
  font-size: 16px;
  font-weight: 500;
}

.mobile-navbar {
  position: fixed;
  top: 0;
  background-color: $app-background-color;
  width: 100%;
  z-index: 999;
  padding: 0 24px;

  > .adm-nav-bar {
    transition: height 0.1s ease;
    height: $topbar-mobile-height;
  }

  @media screen and (min-width: $small-screen) {
    max-width: $small-screen;
    padding: 0;
  }

  @media screen and (min-width: $large-screen) {
    display: none; // only show on mobile
  }

  &.mobile-navbar-border-bottom {
    border-bottom: 1px solid $input-border;
  }
}

.mobile-navbar-scrolled {
  > .adm-nav-bar {
    height: $topbar-mobile-height-scrolled;
  }
}