@import "src/theme/variables/colors";
@import "src/theme/variables/spaces";

.alert__wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1DECC7;
  color: #000;
  padding: 16px;
  font-size: 10px;
  font-weight: 600;

  @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
    left: 50%;
    transform: translateX(-50%);
    max-width: $small-screen;
  }
}

.alert__title {
  font-size: 14px;
  margin-bottom: 16px;
}

.alert__close {
  font-size: 16px;
  margin-bottom: 16px;
  text-align: right;
  cursor: pointer;
}

.alert__button {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  padding: 8px 14px !important;
  font-size: 11px !important;
  display: flex;
  align-items: center;

  & > span {
    line-height: 11px;
  }

  &:hover {
    background-color: #000 !important;
    color: #fff !important;
    border: none !important;
  }
}

.alert__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}