@import "src/theme/variables/colors";

.ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  height: auto !important;
  line-height: 30px !important;

  & > .ant-select-selection-search {
    left: 0 !important;
    right: 0 !important;

    & > input {
      height: auto !important;
    }
  }

  &:after {
    line-height: 30px !important;
  }
}

.ant-select-item-option-active {
  background-color: $secondary !important;
  color: $gray-0 !important;
}

.ant-select {
  color: $gray-1;
  width: 100%;
}

.ant-select-selection-placeholder {
  line-height: 22px !important;
  width: 200px;
}

.ant-select-dropdown {
  //margin-left: -8px !important;
}