@import "override";
@import "src/theme/variables/spaces";
@import "src/theme/variables/colors";

.filters-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

// Remove borders
.borderless {
  --border-top: none !important;
  --border-bottom: none !important;
  --border-inner: none !important;
}

.base-padding {
  padding: $app-padding;
}

.regular-padding {
  padding: $app-padding;
  min-height: 100%;
  //overflow-y: auto;
  overscroll-behavior: contain;
  max-width: $small-screen;

  @media screen and (min-width: $small-screen) {
    padding: $app-padding 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 0 32px 0;
    max-width: 100%;
  }
}

.regular-padding-horizontal {
  padding: 0 $app-padding;

  @media screen and (min-width: $small-screen) {
    padding: 0;
  }

  @media screen and (min-width: $large-screen) {
    padding: 0 0 $app-padding 0;
    max-width: 100%;
  }
}

.padding-vertical {
  //padding-top: $app-padding;
  //padding-bottom: $app-padding;
  padding: $app-padding;

  @media screen and (min-width: $large-screen) {
    padding: 0 0 $app-padding 0;
    max-width: 100%;
  }
}

.desktop__main-padding {
  max-width: $small-screen;

  @media screen and (min-width: $large-screen) {
    min-height: 100%;
    padding: 24px 64px;
    max-width: 100%;
  }
}

.children-bottom-margin {
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
}

.absolute-navbar {
  position: absolute;
  left: $app-padding;
  top: $app-padding;
}

.ant-mobile-tag-selected {
  background-color: $primary;
  border-color: $primary;
  color: #fff;
  cursor: default !important;
}

.ant-tag-group {
  & > .ant-tag {
    margin-bottom: 8px !important;
  }
}

.custom-input-textarea {
  background-color: transparent !important;
  border: 1px solid $input-border;
  border-radius: 12px;
  color: $gray-0;
  box-shadow: none;
  margin-top: 8px;
}

.custom-input-border-bottom {
  &:hover {
    border: none;
    box-shadow: none;
  }

  & > .ant-input-wrapper {
    & > input {
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;
      color: $gray-0 !important;
      box-shadow: none !important;

      &:hover {
        box-shadow: none !important;
      }
    }

    & > .ant-input-group-addon {
      background-color: transparent;
      border: none;
      color: $gray-1;

      & > .hidden-addon {
        display: none;
      }
    }
  }
}

.custom-form-input-item-has-error {
  & > .custom-form-input-item-label {
    & > label {
      color: $error !important;
    }
  }

  & > .custom-form-input-item-control {
    & > .custom-form-input-item-control-input {
      & > .custom-form-input-item-control-input-content {
        & > .custom-input-border-bottom {
          border-color: $error !important;
          color: $error !important;
        }
      }
    }
  }

  & > .custom-form-input-item-label, .custom-form-textarea-item-label {
    color: $error;
  }
}

.flex-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.centered {
  text-align: center;
  justify-content: center;
}

.custom-input {
  border-radius: 8px !important;
  //border: none !important;
  border: 1px solid #4A465B !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 12px !important;

  & > .ant-input {
    background-color: transparent;
    color: $gray-0;

    &:hover, &:focus, &:active {
      box-shadow: none;
      border: none;
    }
  }

  & > .ant-input-prefix {
    color: $gray-0 !important;
  }

  & > .ant-input-suffix {
    & > .ant-badge {
      color: $gray-1;
    }
  }
}

.ant-form-item-label > label {
  height: 16px;
}

.custom-form-input-item {
  margin: 0 0 24px 0;
  padding: 8px;
  background-color: $input-background;
  flex-flow: column;
  flex-wrap: wrap;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
  border-radius: 4px;
}

.custom-form-input-item-explain-error {
  color: $error;
}

.custom-form-textarea-item {
  margin: 0 0 24px 0;
  flex-flow: column;
  flex-wrap: wrap;
  vertical-align: top;
}

.custom-form-input-item-label, .custom-form-textarea-item-label {
  text-transform: uppercase;
  color: $gray-2;
  font-size: 12px !important;
}

.custom-form-label-wrapper {
  text-transform:  uppercase;
  color: $gray-2;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;

  & > h4 {
    color: $gray-2 !important;
  }
}

.sticky-navbar {
  position: sticky;
  top: 0;
  background-color: $app-background-color;
  z-index: 9;
}

.mobile-navbar-relative {
  position: relative;
  background-color: inherit;
  padding: 0;
}

.mobile-navbar-scrolled {
  height: $topbar-mobile-height-scrolled !important;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.helvetica {
  font-family: "Helvetica Neue", "Roboto Mono for Powerline", sans-serif;
}

.custom-simple-input {
  background-color: transparent;
  height: 52px;
  border: 1px solid $input-border;
  border-radius: 5px;
  color: #fff;
  &::placeholder {
    color: $input-border;
  }
}

.button__background-color {
  border: none;
  //border-color: $input-border;
  background-color: $app-background-color;
}

.status-tag {
  color: $background-secondary;
  padding: 4px;
  font-weight: 600;
  border-radius: 5px;
}

.clickable {
  cursor: pointer;
}

.margin-bottom-desktop {
  @media screen and (min-width: $small-screen) {
    margin-bottom: 24px;
  }

  @media screen and (min-width: $large-screen) {
    margin-bottom: 32px;
  }
}

.padding-mobile {
  padding: 24px;

  @media screen and (min-width: $small-screen) {
    padding: 0;
  }
}

.button__secondary {
  background-color: $secondary;
  border-color: $secondary;
  color: #fff;
}

.capitalize {
  text-transform: capitalize;
}

.color__gray-1 {
  color: $gray-1 !important;
}

.marginless {
  margin: 0 !important;
}

.responsive-buttons-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media screen and (min-width: $large-screen) {
    flex-direction: row;
    column-gap: 8px;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.gradient-background, .gradient-background:hover {
  background: linear-gradient(89.46deg, #5362E9 9.25%, #6C38B5 51.1%, #B127C8 92.85%);
  border: none;
}