@import "src/theme/variables/colors";

.adm-result {
  background-color: transparent;
}

.ant-result-title {
  color: #fff;
}

.ant-result-subtitle {
  color: $gray-1;
}