@import "~antd/dist/antd.css";
@import "theme/variables/colors";
@import "theme/variables/spaces";

@import "theme";

.App {
  height: calc(100vh - 70px);
  background: $app-background-color;
  //overflow-y: auto;
  width: 100%;

  &.app-not-logged-in {
    height: 100vh;
    position: relative;
    left: 0;
    @media (min-width: $large-screen) {
      margin: auto;
      width: 100%;
      max-width: 100%;
      max-width: $small-screen;
      padding-top: 0;
    }
  }

  &.app-full-width {
    left: 0;
    @media (min-width: $large-screen) {
      width: 100%;
      max-width: 100%;
      overflow-y: auto;
    }
  }

  padding: 0;

  @media screen and (min-width: $large-screen) {
    position: absolute;
    left: $navbar-width;
    max-width: calc(100% - #{$navbar-width});
    width: calc(100% - #{$navbar-width});
    margin-left: 0;
    height: 100vh;
    overflow: hidden;
  }
}

.main__wrapper {
  position: absolute;
  top: $topbar-mobile-height;
  width: 100%;
  //max-width: $small-screen;
  height: calc(100vh - #{$topbar-mobile-height} - 70px);
  overflow-y: auto;
  transition: height 1s ease;
  overscroll-behavior: contain;

  &.main__wrapper-not-logged-in {
    height: calc(100vh - #{$topbar-mobile-height});
  }

  @media screen and (min-width: $small-screen) {
    padding: 0 calc((100% - #{$small-screen}) / 2);
  }

  @media screen and (min-width: $large-screen) {
    position: absolute;
    top: $topbar-desktop-height;
    width: 100%;
    max-width: 100%;
    padding: 0;
    height: calc(100vh - #{$topbar-desktop-height});

    &:first-child {
      margin-top: 64px;
    }
    &:last-child {
      margin-bottom: 64px;
    }

    &.main__wrapper-not-logged-in {
      margin: 0;
    }
  }
}

.main__wrapper-scrolled {
  top: $topbar-mobile-height-scrolled;
  height: calc(100vh - #{$topbar-mobile-height-scrolled} - 70px);

  &.main__wrapper-not-logged-in-scrolled {
    height: calc(100vh - #{$topbar-mobile-height-scrolled});
  }

  @media screen and (min-width: $large-screen) {
    top: $topbar-desktop-height-scrolled;
    height: calc(100vh - #{$topbar-desktop-height-scrolled});
  }
}

