@import "src/theme/variables/colors";

.ant-card-head {
  background-color: $background-secondary;
}

.ant-card-head-title {
  white-space: break-spaces;
  color: $gray-1;
}

.ant-card-body {
  background-color: $background-secondary;
  color: #fff;
  padding-top: 0;
}